import React, { useEffect, useState } from 'react';
import { ADSERVER_ID } from '@/_constant/appConstant';

function RightBanner({stateIds}) {
  return (
    <div key={stateIds} className="d-flex flex-column gap-2 position-sticky top-0">
      <div>
        <ins data-revive-zoneid="4" data-revive-state-id={stateIds} data-revive-id={ADSERVER_ID}></ins>
      </div>
      <div>
        <ins data-revive-zoneid="3" data-revive-state-id={stateIds} data-revive-id={ADSERVER_ID}></ins>
      </div>
    </div>
  );
}

export default RightBanner;
