import "./Header.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Login from "../../../../pages/auth/login/Login";
import { getLoginFromState, setLoginPopup } from "@/store/reducer/loginSlice/loginSlice";
import { signOut } from "@/endpoints/amplify/auth";
import { ADZONE, API_PUBLIC_HOST, CONTACT_US, MY_ADDRESS, MY_PROFILE, MY_TRANSACTION, OFFERZONE, PAYMENTSUMMARY, PREFIX_LISTING, PREFIX_POSTING } from "@/_constant/urlconstants";
import { getUserData } from "@/store/reducer/userSlice/userSlice";
import Loader from "../../../public/plugins/loader/loader";
import { setSellCategoriesData, setServiceCategoriesData } from "@/store/reducer/categoriesListSlice/categories";
import { COMPANY_LOGO, PROFILE_PLACEHOLDER_IMG } from "@/_constant/image-url-constants";
import { useLanguageContext } from "@/hooks/context-hooks";
import Button from "@/_component/Common/button/Button";
import { useLoginToggle } from "@/hooks/login-toggle";
import { copyToClipboard, swapLabel } from "@/utility/common-utils";
import request from "@/apis/request"; 

export default function Header({setHeaderData }) {
  const { languageData, changeLanguage, language } = useLanguageContext();
  const handleLoginToggle = useLoginToggle();
  const userDetails = useSelector(getUserData);
  const [sellCategoriesList, setSellCategoriesList] = useState([]);
  const [serviceCategoriesList, setServiceCategoriesList] = useState([]);
  const [menus, setMenus] = useState([]);

  const removeSellCategories = [79, 169, 168, 166, 139, 126, 240, 1, 277, 274, 271, 267, 100, 255, 250, 245, 243, 240, 234, 228, 227, 206, 188];
  const showSellCategoriesBuy = [1, 182, 97, 106, 125, 113, 170, 126, 166, 139, 79, 175];
  const removedBuyCategories = [206, 227, 228, 234, 240, 243];

  const loginTogglePopup = useSelector(getLoginFromState);
  const isLoggedIn = useSelector(getUserData);
  const [languageList, setLanguageList] = useState([]);
  let [userPlanIdList, setUserPlanIdList] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [sellList, setSellList] = useState([]);

  const schema = {
    id: 0,
    code: "en",
    name: "english",
    display: "English",
  };
  const [disLanguage, setDisLanguage] = useState(schema);

  const getFarmersPLanId = async (userDetails) => {
    console.log(userDetails)
    const url = `${API_PUBLIC_HOST}/user/viewFarmerPlan?userId=` + encodeURIComponent(userDetails?.userId);
    let dataUserPlan = await request({
      url: url,
      method: "GET"
    });
    setUserPlanIdList(dataUserPlan?.response);
    setHeaderData({
      dataUserPlan :dataUserPlan?.response
    })

  };

  useEffect(() => {
    let item = localStorage.getItem('language');
    item = JSON.parse(item)
    setDisLanguage(item)
    fetchLanguageList();
    listUserAccessCategoriesAndAssigneeReviewRequest()
    getFarmersPLanId(userDetails);
  }, []);


  const handleChangeRegistrationspage = (e) => {
    e.preventDefault();
    dispatch(setLoginPopup(false));
    navigate("/register");
  };



  useEffect(() => initMenus(sellList, sellCategoriesList, serviceCategoriesList), [languageData, sellList, sellCategoriesList]);

  const sellListCategory = (sellCategoriesList) => {
    if (sellCategoriesList && sellCategoriesList.length > 0) {
      const filteredSellList = sellCategoriesList
        .filter(item => !removeSellCategories.includes(+item?.id))
        .map(item => {
          return item;
        }).sort((a, b) => b.id - a.id);

      setSellList(filteredSellList);
      return filteredSellList;
    }
  }

  const fetchLanguageList = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_PUBLIC_HOST}/lookup/language`,
      headers: {
        accept: "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        const items = [];
        let item = localStorage.getItem('language');
        response?.data?.response?.forEach((item, i) => {
          items.push(item);
        });
        setLanguageList(items);
        if (item === null) {
          setDisLanguage(items[0]);
          item = items[0];
          localStorage.setItem('language', JSON.stringify(item));
        } else {
          item = JSON.parse(item)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLanguageChange = (e) => {
    e.preventDefault();
    const languageName = e.target.getAttribute('data-lang');
    const item = languageList.find(obj => obj.name === languageName);
    setDisLanguage(item);
    changeLanguage(item.name);
    localStorage.setItem('language', JSON.stringify(item));
  };

  async function listUserAccessCategoriesAndAssigneeReviewRequest(jwtToken) {
    const headers = { Authorization: jwtToken, 'accept': 'application/json', };
    const serviceCategories = await axios.get(API_PUBLIC_HOST + '/category/listCategories?access_id=1&level=0', { headers });
    const sellCategories = await axios.get(API_PUBLIC_HOST + '/category/listCategories?access_id=4&level=0', { headers });
    if (sellCategories && serviceCategories) {
      setServiceCategoriesList(serviceCategories?.data?.response)
      setSellCategoriesList(sellCategories?.data?.response)
      dispatch(setServiceCategoriesData(Object.assign([], serviceCategories?.data?.response)));
      dispatch(setSellCategoriesData(Object.assign([], sellCategories?.data?.response)));
      const filteredSellList = sellListCategory(sellCategories?.data?.response);
      if (languageData.length > 0) initMenus(filteredSellList, sellCategories?.data?.response, serviceCategories?.data?.response)
    }
  }

  const openCategorySellBuy = (cat, id) => {
    let temp = sellCategoriesList?.find((item) => item.id === id);
    if (temp == undefined) {
      temp = serviceCategoriesList?.find((item) => item.id === id);
    }
    if (cat === "sell") {
      let g = btoa("gen");
      const itemData = JSON.stringify({ UserPlanId: temp?.user_plan_id, category_id: id, name: temp?.name, editType: 'general' });
      navigate(`/sell/${encodeURIComponent((temp?.name || '').replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?id=${encodeURIComponent(temp?.id || '') + "&@=" + encodeURIComponent(g || '')}`, { state: { item: itemData, userPlan: userPlanIdList } });
    } else if (cat === "buy") {
      const itemData = JSON.stringify({ user_plan_id: temp?.user_plan_id, category_id: id, name: temp?.name, type: 'search' });
      navigate(`/buy/${encodeURIComponent(temp?.name.replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?${encodeURIComponent(temp?.id)}`, { state: { item: itemData } });
    } else {
      const itemData = JSON.stringify({ user_plan_id: temp?.user_plan_id, category_id: id, name: temp?.name, type: 'search' });
      navigate(`/services/${encodeURIComponent(temp?.name.replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w\s-]/g, ''))}?${encodeURIComponent(temp?.id)}`, { state: { item: itemData } });
    }
  };

  function initMenus(sellList, sellCategoriesList, serviceCategoriesList) {
    const sellMenus = [];
    const buyMenus = [];
    const serviceMenus = [];
    sellList.forEach(sell => {
      if (sell.is_active) sellMenus.push({ "type": "sell", "name": language(swapLabel(sell.displayCode)), "link": sell.id, on_click: openCategorySellBuy, sub_menus: [] });
    });
    sellCategoriesList.forEach(buy => {
      if (showSellCategoriesBuy.includes(+buy?.id) && buy.is_active) buyMenus.push({ type: "buy", "name": language(swapLabel(buy.displayCode)), "link": buy.id, on_click: openCategorySellBuy, sub_menus: [] });
    });
    serviceCategoriesList.forEach(service => {
      if (service.is_active) serviceMenus.push({ "type": "service", "name": language(service.displayCode), "link": service.id, on_click: openCategorySellBuy, sub_menus: [] });
    });
    const menus = [
      {
        name: language("label.home"),
        link: "/",
        active: true,
        sub_menus: [],
      },
      {
        name: language("label.sell"),
        link: "#",
        sub_menus: sellMenus,
      },
      {
        name: language("label.buy"),
        link: "#",
        sub_menus: buyMenus,
      },
      {
        name: language("label.service"),
        link: "#",
        sub_menus: serviceMenus,
      },
      {
        name: language("label.offer_zone"),
        link: OFFERZONE,
        sub_menus: [],
      },
      {
        name: language("label.ad_zone"),
        link: ADZONE,
        sub_menus: [],
      },
      {
        name: language("label.my_listing"),
        link: PREFIX_LISTING,
        sub_menus: [],
      },
      {
        name: language("label.my_payment"),
        link: PAYMENTSUMMARY,
        sub_menus: [],
      },
      {
        name: language("label.my_posting"),
        link: PREFIX_POSTING,
        sub_menus: [],
      }
    ];
    setMenus([...menus]);
  }

  function isUserLoggedIn() {
    if (isLoggedIn && Object.keys(isLoggedIn).length === 0) {
      return (
        <Button primary solid small noSpacing className="py-1 px-2"
          id="loginregisterdiv"
          onClick={handleLoginToggle}>{language("label.login")}</Button>
      );
    }
    return (
      <a href="#profile-dropdown" className="d-block link-light text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <span className="me-md-2 d-none d-md-inline text-uppercase">{isLoggedIn.name}</span>
        <img src={isLoggedIn.profileUrl ?? PROFILE_PLACEHOLDER_IMG} alt="profile image" width="32" height="32" className="rounded-circle" />
      </a>
    );
  }

  function createMenu(level = 0, menus) {
    if (!menus || menus.length <= 0) return;
    return menus.map(menu => {
      if (!menu.name) return null;
      const hasSubMenu = menu.sub_menus && menu.sub_menus.length > 0;
      if (!hasSubMenu) {
        return (
          <li className={level <= 0 ? "nav-item scale-1 transition transition-duration-500 hover-scaleX-1-3" : ""} key={menu.name}>
            <Link className={level <= 0 ? `nav-link kg-lg-white text-nowrap ${!!menu.active ? "active" : ""}` : "dropdown-item"} onClick={() => menu.on_click && menu.on_click(menu.type, menu.link)} to={menu.link}>{swapLabel(menu.name)}</Link>
          </li>
        )
      }
      return (
        <li className="nav-item dropdown" id={`${menu.name.toLowerCase()}-dropdown`} key={menu.name}>
          <Link className="nav-link kg-lg-white text-nowrap dropdown-toggle scale-1 transition transition-duration-500 hover-scaleX-1-3" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{menu.name}</Link>
          <ul className="dropdown-menu border-0 shadow">
            {createMenu(level + 1, menu.sub_menus)}
          </ul>
        </li>
      );
    })
  }

  return (
    <>
      <header className="text-white">
        <nav className="navbar navbar-expand-lg px-sm-3 pt-1 px-2 pb-lg-0 pb-2 kg-bg-light-dark">
          <div className="container">
            <div className="logo-container me-lg-3">
              <button className="navbar-toggler text-white border-0 outline-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#kgNavbar" aria-controls="kgNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="bi bi-grid-3x3-gap-fill"></span>
              </button>
              <a className="navbar-brand text-white" href="/"><img src={COMPANY_LOGO} alt="Kisan Gateway Logo" className="img-fluid" /></a>
            </div>

            <div className="navigation-container flex-grow-1 d-flex flex-column">
              <div className="d-flex justify-content-end align-items-center gap-3 flex-nowrap pe-2 py-2" style={{ width: "100%" }}>
                <div>
                  <div className="dropdown cursor-pointer" role="listbox">
                    <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <span className="me-1 text-uppercase">{disLanguage?.display}</span>
                    </div>
                    <ul className="dropdown-menu" onClick={handleLanguageChange}>
                      {languageList.map(language => <li className="dropdown-item" data-lang={language.name} key={language.id}>{language?.display}</li>)}
                    </ul>
                  </div>
                </div>
                <div className="notification"><i className="fs-5 bi bi-bell-fill"></i></div>
                <div className="dropdown">
                  {isUserLoggedIn()}
                  <ul className="dropdown-menu dropdown-menu-end text-small" id="profile-dropdown">
                    <li>
                      <div className="px-3">
                        <div className="text-bold">{isLoggedIn.name}</div>
                        <div>{isLoggedIn.phoneNumber}</div>
                      </div>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                      <div className="user-select-all px-3 d-inline-flex align-items-center">
                        <span className="me-2" onClick={() => copyToClipboard(isLoggedIn.referralCode)}>{isLoggedIn.referralCode}</span>
                        <Button noSpacing onClick={() => copyToClipboard(isLoggedIn.referralCode)}><i className="bi bi-share"></i></Button>
                      </div>
                    </li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to={MY_PROFILE}><i className="bi bi-person pe-2"></i>Profile</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><Link className="dropdown-item" to={MY_TRANSACTION}><i className="bi bi-credit-card pe-2"></i>My Transactions</Link></li>
                    <li><Link className="dropdown-item" to={MY_ADDRESS}><i className="bi bi-geo-alt pe-2"></i>My Address</Link></li>
                    <li><Link className="dropdown-item" to={CONTACT_US}><i className="bi bi-question-circle pe-2"></i>Support</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li className="text-center"><Button onClick={signOut} noSpacing className="py-2 text-bold">Log out</Button></li>
                  </ul>
                </div>
              </div>

              <div className="d-flex align-items-center kg-bg-green rounded-top-3 mt-lg-1" style={{ width: "100%" }}>
                <div className="offcanvas offcanvas-end" id="kgNavbar">
                  <div className="offcanvas-header border-bottom">
                    <h5 className="offcanvas-title" id="kgNavbar">Kisan Gateway</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#kgNavbar" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body justify-content-lg-end">
                    <ul className="navbar-nav me-auto me-lg-0 gap-2 px-2">
                      {createMenu(0, menus)}
                    </ul>
                  </div>
                  <div className="offcanvas-footer sticky-bottom">
                    <div className="px-4 py-2 border-top d-block d-lg-none">
                      <div className="d-flex justify-content-between align-items-center fs-4">
                        <i className="bi bi-telephone"></i>
                        <i className="bi bi-cloud-arrow-down"></i>
                        <i className="bi bi-share"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {loginTogglePopup && (
        <Login
          handleChangeRegistrationspage={handleChangeRegistrationspage}
          show={loginTogglePopup ? "show" : "hide"}
        ></Login>
      )}
      {/******* for all component ************* */}
      <Loader />
    </>
  );
}
