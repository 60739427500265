import TopBanner from "@/_component/Common/ads/topbanner/TopBanner";
import BottomBanner from "@/_component/Common/ads/bottombanner/BottomBanner";
import RightBanner from "@/_component/Common/ads/rightbanner/RightBanner";
import { useAdsRefresh } from "@/hooks/ads-refresh";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { API_PUBLIC_HOST } from "@/_constant/urlconstants";
import axios from "axios";
import { getJwtToken } from "@/endpoints/amplify/auth";

export default function MainAdsLayout({ children, sideBanner = false, className = "", noSpacing = false }) {
    const [stateIds, setStateIds] = useState(null);
    const initData = async () => {
        const category = sessionStorage.getItem("selectedDrop");
        if (category !== null && category !== "") {
            const token = await getJwtToken();
            await getCategoryAddressList(token, category);
        }
    }
    useEffect(() => {
        initData();
    }, []);

    useAdsRefresh(stateIds);

    const getCategoryAddressList = async (jwtToken, selectedCategory) => {
        const URL = `${API_PUBLIC_HOST}/user/listAddressForCategory?categoryId=${selectedCategory}`;
        const response = await axios({
            method: "get",
            url: URL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: jwtToken,
            }
        });
        let states = await response.data.response?.map(obj => obj.state);
        const uniqueStates = new Set(states);
        states = [...uniqueStates];
        setStateIds(states.join(","));
    }


    function showSideBanner() {
        if (sideBanner) {
            return (
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-fill">
                        {children}
                    </div>
                    <div className="pt-5 pt-lg-0"><RightBanner stateIds={stateIds} /></div>
                </div>
            )
        }
        return children;
    }

    return (
        <main>
            <TopBanner stateIds={stateIds} />
            <section className="bg-light">
                <div className={classNames("container position-relative top-n5 rounded-3 bg-white", className, !noSpacing && "py-5 px-md-5 px-3")}>
                    {showSideBanner()}
                </div>
            </section>
            <BottomBanner stateIds={stateIds} />
        </main>
    );
}