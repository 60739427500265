import React from 'react';
import "../banner.css";
import { ADSERVER_ID } from '@/_constant/appConstant';
const TopBanner = ({stateIds}) => {

  return (
    <div className="pg_top_banner">
      <div className="pg_top_bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top_banner_img" key={stateIds}>
                <ins data-revive-zoneid="5" data-revive-state-id={stateIds} data-revive-id={ADSERVER_ID}></ins>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
