import { DateTime } from "luxon";

const ACCESS_TYPES = {
  0: "others",
  1: "service-provider",
  2: "agent",
  3: "farmer",
  4: "dealer"
}

export function copyToClipboard(text = "") {
  navigator && navigator.clipboard.writeText(text);
}

export function categoryDisplayCode(category) {
  if (category.category_id === '-1') {
    return "label.farmer";
  } else if (category.category_id === '-2') {
    return "label.agent";
  } else if (category.category_id === '-3') {
    return "label.others";
  } else if (category.category_id === '999') {
    return "label.assignee";
  }
  return category?.category_display_code;
}

export function accessTypeName(accessId) {
  return ACCESS_TYPES[accessId];
}

export function formatTimeISOFormat(time, fromFormat) {
  return DateTime.fromFormat(time, fromFormat).toISOTime();
}

export function formatDateISOFormat(date, fromFormat) {
  return DateTime.fromFormat(date, fromFormat).toISODate();
}

export function formatTime(time, fromFormat, format) {
  return DateTime.fromFormat(time, fromFormat).toFormat(format);
}

export function formatDate(date, fromFormat, format) {
  return DateTime.fromFormat(date, fromFormat).toFormat(format);
}

export function formatDateTime(datetime, fromFormat, format) {
  return DateTime.fromFormat(datetime, fromFormat).toFormat(format);
}

export function formatFromISODate(date, format) {
  return DateTime.fromISO(date).toFormat(format);
}

export function getYearMonth(date, format = 'yyyy-MM') {
  return DateTime.fromISO(date).toFormat(format);
}

export function minusMonth(date, fromFormat, format) {
  return DateTime.fromFormat(date, fromFormat).minus({ months: 1 }).toFormat(format);
}

export function swapLabel(displayCode = "") {

  if (displayCode === 'category.machinery') {
    return 'category_machinery';
  } else if (displayCode === 'category.machinery_traders') {
    return 'category_machinery';
  }
  return displayCode;
}

export function getCategoryNames(item) {

  if (item?.id === 171 || item?.categoryId === 171) {
    return 'category.paddy_straw';
  } else if (item?.id === 172 || item?.categoryId === 172) {
    return 'category.wheat_straw';
  } else if (item?.id === 173 || item?.categoryId === 173) {
    return 'category.sugarcane';
  } else if (item?.id === 172 || item?.categoryId === 172) {
    return 'category.forest_waste';
  } else if (item?.id === 307 || item?.categoryId === 307) {
    return 'category.fruits';
  } else if (item?.id === 308 || item?.categoryId === 308) {
    return 'category.vegetables';
  } else if (item?.id === 115 || item?.categoryId === 115) {
    return 'category.sale';
  } else if (item?.id === 116 || item?.categoryId === 116) {
    return 'category.yearly_contract';
  } else if (item?.id === 117 || item?.categoryId === 117) {
    return 'category.contract_farming';
  } else if (item?.id === 119 || item?.categoryId === 119 || item?.categoryId === 123) {
    return 'category.sale';
  } else if (item?.id === 120 || item?.categoryId === 120) {
    return 'category.yearly_contract';
  } else if (item?.id === 121 || item?.categoryId === 121) {
    return 'category.contract_farming';
  } else if (item?.id === 124 || item?.categoryId === 124) {
    return 'category.rentals';
  } else if (item?.id === 107 || item?.categoryId === 107) {
    return 'category.grains';
  } else if (item?.id === 108 || item?.categoryId === 108) {
    return 'category.pulses';
  } else if (item?.id === 109 || item?.categoryId === 109) {
    return 'category.spices';
  } else if (item?.id === 110 || item?.categoryId === 110) {
    return 'category.dry_fruits';
  } else if (item?.id === 111 || item?.categoryId === 111) {
    return 'category.flowers';
  } else if (item?.id === 112 || item?.categoryId === 112) {
    return 'category.other_crop';
  } else if (item?.id === 105 || item?.categoryId === 105) {
    return 'category.dog';
  } else if (item?.id === 98 || item?.categoryId === 98) {
    return 'category.cow';
  } else if (item?.id === 103 || item?.categoryId === 103) {
    return 'category.goat';
  } else if (item?.id === 104 || item?.categoryId === 104) {
    return 'category.sheep';
  } else if (item?.id === 41 || item?.categoryId === 41) {
    return 'category.tractor';
  } else if (item?.id === 20 || item?.categoryId === 20) {
    return 'category.straw_baler';
  } else if (item?.id === 21 || item?.categoryId === 21) {
    return 'category.sugarcane_baler';
  } else if (item?.id === 22 || item?.categoryId === 22) {
    return 'category.forest_waste_baler';
  } else if (item?.id === 59 || item?.categoryId === 59) {
    return 'category.straw_baler';
  } else if (item?.id === 60 || item?.categoryId === 60) {
    return 'category.sugarcane_reaper';
  } else if (item?.id === 61 || item?.categoryId === 61) {
    return 'category.sugarcane_baler';
  } else if (item?.id === 55 || item?.categoryId === 55) {
    return 'category.sugarcane_reaper';
  } else if (item?.id === 56 || item?.categoryId === 56) {
    return 'category.straw_reaper';
  } else if (item?.id === 183 || item?.categoryId === 183) {
    return 'category.stalian';
  } else if (item?.id === 184 || item?.categoryId === 184) {
    return 'category.gelding';
  } else if (item?.id === 185 || item?.categoryId === 185) {
    return 'category.colt';
  } else if (item?.id === 187 || item?.categoryId === 187) {
    return 'category.filly';
  } else if (item?.id === 57 || item?.categoryId === 57) {
    return 'category.forest_waste_reaper';
  }else if (item?.id === 43 || item?.categoryId === 43) {
    return 'category.combine_harvester';
  }else if (item?.id === 44 || item?.categoryId === 44) {
    return 'category.potato_harvester';
  }else if (item?.id === 45 || item?.categoryId === 45) {
    return 'category.sugarcane_harvester';
  }else if (item?.id === 46 || item?.categoryId === 46) {
    return 'category.carrot_harvester';
  }else if (item?.id === 47 || item?.categoryId === 47) {
    return 'category.cotton_harvester';
  }else if (item?.id === 48 || item?.categoryId === 48) {
    return 'category.groundnut_digger_shaker';
  }else if (item?.id === 49 || item?.categoryId === 49) {
    return 'category.turmeric_harvester';
  }else if (item?.id === 50 || item?.categoryId === 50) {
    return 'category.tapioca_harvester';
  }else if (item?.id === 51 || item?.categoryId === 51) {
    return 'category.coconut_tree_climber';
  }else if (item?.id === 52 || item?.categoryId === 52) {
    return 'category.banana_harvester';
  }else if (item?.id === 53 || item?.categoryId === 53) {
    return 'category.tea_plucking_machine';
  }

  return null;
}

export function toggleDropdown(selector) {
  if (!selector) {
    console.error("selector value is required to toggle dropdown");
    return;
  }
  const dropdown = document.getElementById(selector);
  window.bootstrap.Dropdown.getOrCreateInstance(dropdown.lastChild).toggle();
}