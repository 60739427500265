import "./App.css";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { Provider } from "react-redux";
import { store } from "@/store/store";
import Loader from "@/_component/public/plugins/loader/loader";
import { isLoggedInUser } from "./endpoints/amplify/auth";
import Header from "@/_component/Common/theme/header/Header";
import Footer from "@/_component/Common/theme/footer/Footer";
import Register from "@/pages/auth/Signup";
import VerifyOTP from "@/pages/auth/VerifyOTP";
import ResetPassword from "@/pages/auth/ResetPassword";

import {
  CATEGORIESLIST,
  MACHINERYREAPER,
  MACHINERYBALERS,
  TRACTOR,
  HARVESTER,
  FI,
  TRACTORTRADER,
  HARVESTERTRADERS,
  FITRADERS,
  REAPERTRADER,
  BALERSTRADER,
  TYRE,
  CROPREG,
  BUFFALO,
  CAMEL,
  COW,
  DOG,
  GOAT,
  OX,
  SHEEP,
  SILAGE,
  HORSETRADERS,
  BIOMASSTRADERS,
  FRUITSVEGREG,
  PROPERTYINFORMATIONFORM,
  PFS,
  DOMESTIC,
  AGRICULTURE,
  INDUSTRIAL,
  DEALERBUYPRESEARCH,
  SEARCHRESULTPAGE,
  GREATDEALS,
  VERIFYREGISTER,
  REGISTERAS,
  SERVICEPROVIDER,
  DEALERPROVIDER,
  AGENTPROVIDER,
  FARMERPROVIDER,
  OTHERSPROVIDER,
  SERVICELIST,
  CATEGORIESITEM,
  PRIVACYPOLICY,
  TERMCONDITION,
  REFUNDPOLiCY,
  ADSPOLICY,
  SERVICEPROVIDERLIST,
  SERVICEPROVIDERPOSTING,
  MACHINERYSERVICE,
  BOREWELL,
  BANKSERVICE,
  INSURENCESERVICE,
  WELDINGFAB,
  WATERPUMP,
  HOURSEDOGTRANAIR,
  ARTIFICIALLIVESTOCKBREEDING,
  NATURALLIVESTOCKBREEDING,
  DEALERONBOARDFROM,
  DEALERPLANSLIST,
  AGENTREG,
  OTHERSREG,
  FARMERREG,
  SERVICESREG,
  ARTIFICIALHORSEBREEDING,
  NATURALHORSEBREEDING,
  WATERSOILTESTMANURE,
  SOILTEST,
  ADDZONELIST,
  ADDRESS,
  UPLOADPHOTOS,
  PREVIEWLISTINGPAGE,
  GENERAL_LISTING,
  DEALER_LISTING,
  MYPOSTINGS,
  DEALERPOSTINGS,
  PAYMENTSUMMARY,
  OFFERINFORMATION,
  OFFERRESULTS,
  OFFERZONE,
  OFFERZONELISTINGS,
  OFFERZONEPOSTING,
  PREVIEWPOSTINGPAGE,
  ADZONE_POSTING,
  CONTACT_US,
  ABOUT_US,
  ADZONE,
  MY_TRANSACTION,
  MY_ADDRESS,
  MY_PROFILE,
  PROFILE_DETAILS,
  MY_DEALERSHIP,
  CHANGE_PASSWORD,
  AGENT_BANK_DETAILS,
  AGENT_CLUSTER,
} from "./_constant/urlconstants";

import Categories from "./_component/choosepost/categorieslist/categories";
import BaseCreatePost from "./_component/Common/base-create-post";
import BaseListing from "./_component/MyListing/base-listing";
import BasePosting from "./_component/MyPostings/base-posting";
import Profile from "@/pages/profile/Profile";
import { LanguageProvider } from "./context/language-context";
import Dealership from "./pages/profile/MyDealership";
import DealershipDetail from "./pages/profile/DealershipDetail";
import ProfileDetail from "./pages/profile/ProfileDetail";
import BaseTransaction from "./pages/transaction/BaseTransaction";
import TransactionDetails from "./pages/transaction/TransactionDetails";
import MyAddress from "@/pages/profile/MyAddress";

const Home = React.lazy(() => import("@/pages/home"));

/********* Machinery  pages **************** */
const ChangePassword = React.lazy(() => import("@/pages/profile/ChangePassword"));
const Reaper = React.lazy(() =>
  import("./_component/from/dealer/machinary/reaper/create_post_reaper")
);
const Baler = React.lazy(() =>
  import("./_component/from/dealer/machinary/balers/create_post_balers")
);
const Tractor = React.lazy(() =>
  import("./_component/from/dealer/machinary/tractor/create_postPage_tractor")
);
const FarmingImplementation = React.lazy(() =>
  import(
    "./_component/from/dealer/machinary/farmingimplements/create_postPage_FI"
  )
);
const Harvester = React.lazy(() =>
  import(
    "./_component/from/dealer/machinary/harvester/create_postPage_harvester"
  )
);
/********* Machinery  pages **************** */
const TractorTraders = React.lazy(() =>
  import(
    "./_component/from/dealer/machinarytraders/tractor/create_postPage_tractor"
  )
);
const HarvesterTraders = React.lazy(() =>
  import(
    "./_component/from/dealer/machinarytraders/harvester/create_postPage_harvester"
  )
);
const FarmingImplementationTraders = React.lazy(() =>
  import(
    "./_component/from/dealer/machinarytraders/farmingimplements/create_postPage_FI"
  )
);
const ReaperTrader = React.lazy(() =>
  import("./_component/from/dealer/machinarytraders/reaper/create_post_reaper")
);
const BalerTraders = React.lazy(() =>
  import("./_component/from/dealer/machinarytraders/balers/create_post_balers")
);
const Tyre = React.lazy(() =>
  import("./_component/from/dealer/tyre/lmv/create_postPage_tyre")
);
const CropsReg = React.lazy(() =>
  import("./_component/from/dealer/crops/create_postPage_crop_reg")
);
const Buffalo = React.lazy(() =>
  import("./_component/from/dealer/livestock/buffalo/create_postPage_buffalo")
);
const Camel = React.lazy(() =>
  import("./_component/from/dealer/livestock/camel/create_postPage_camel")
);
const Cow = React.lazy(() =>
  import("./_component/from/dealer/livestock/cow/create_postPage_cow")
);
const Dog = React.lazy(() =>
  import("./_component/from/dealer/livestock/dog/create_postPage_dog")
);
const Goat = React.lazy(() =>
  import("./_component/from/dealer/livestock/goat/create_postPage_goat")
);
const Ox = React.lazy(() =>
  import("./_component/from/dealer/livestock/ox/create_postPage_ox")
);
const Sheep = React.lazy(() =>
  import("./_component/from/dealer/livestock/sheep/create_postPage_sheep")
);
const Silage = React.lazy(() =>
  import("./_component/from/dealer/silage/create_postPage_silage")
);
const HorseTraders = React.lazy(() =>
  import(
    "./_component/from/dealer/horse/horsetraders/create_postPage_horse_traders"
  )
);
const BiomassTrader = React.lazy(() =>
  import(
    "./_component/from/dealer/biomasstraders/create_postPage_biomass_trader"
  )
);
const FruitInformationForm = React.lazy(() =>
  import(
    "./_component/from/dealer/fruitsvegitable/create_postPage_fruits_veg_reg"
  )
);
const PropertyInformationForm = React.lazy(() =>
  import("./_component/from/dealer/property/create_postPage_property")
);
const PFSInformation = React.lazy(() =>
  import("./_component/from/dealer/pfs/create_postPage_PFS")
);
const Domestic = React.lazy(() =>
  import(
    "./_component/from/dealer/moterpump/domestic/create_postPage_domestic_pumpmotor"
  )
);
const Agriculture = React.lazy(() =>
  import(
    "./_component/from/dealer/moterpump/agricultural/create_postPage_agriculture_pumpmotor"
  )
);
const Industrial = React.lazy(() =>
  import(
    "./_component/from/dealer/moterpump/industrial/create_postPage_industrial_pumpmotor"
  )
);

const DealerBuyPreSearch = React.lazy(() =>
  import("./_component/choosepost/searchcategories/dealer_buy_preSearch")
);
const SearchResultPage = React.lazy(() =>
  import("./_component/choosepost/searchresult/searchresult")
);
const GreatDealsSearch = React.lazy(() =>
  import("./_component/choosepost/great_deals/great_deals")
);
const SearchCategories = React.lazy(() =>
  import("./_component/choosepost/searchList/search")
);
const Buy = React.lazy(() => import("./_component/choosepost/buy/buy"));

const RegisterAs = React.lazy(() =>
  import("./pages/home/registeras/registeras")
);
const AgentsProvider = React.lazy(() =>
  import("./_component/onboardusers/features/agentprovider")
);
const DealerProvider = React.lazy(() =>
  import("./_component/onboardusers/features/dealerprovider")
);
const FarmerProvider = React.lazy(() =>
  import("./_component/onboardusers/features/farmerprovider")
);
const ServiceProvider = React.lazy(() =>
  import("./_component/onboardusers/features/serviceprovider")
);
const OthersProvider = React.lazy(() =>
  import("./_component/onboardusers/features/otherprovider")
);
const CategoriesItem = React.lazy(() =>
  import("./_component/onboardusers/categorieslist/choosecategories")
);
const DealerRegisterForm = React.lazy(() =>
  import("./_component/onboardusers/onbardingcategoriesfrom/onboarding")
);
const Agent = React.lazy(() =>
  import("./_component/onboardusers/onbardingcategoriesfrom/agent")
);
const Farmer = React.lazy(() =>
  import("./_component/onboardusers/onbardingcategoriesfrom/farmer")
);
const Others = React.lazy(() =>
  import("./_component/onboardusers/onbardingcategoriesfrom/others")
);
const ServiceProviderReg = React.lazy(() =>
  import("./_component/onboardusers/onbardingcategoriesfrom/serviceprovider")
);
const DealerPlansList = React.lazy(() =>
  import("./_component/onboardusers/planlist/dealerlist/dealerlistplan")
);

/* Policies */
const Privacypolicy = React.lazy(() => import("@/pages/policy/privacypolicy/privacypolicy"));
const TermCondition = React.lazy(() => import("@/pages/policy/termsconditions/termcondition"));
const RefundPolicy = React.lazy(() => import("@/pages/policy/refund/refund"));
const AdsPolicy = React.lazy(() => import("@/pages/policy/adspolicy/adspolicy"));

const ContactUs = React.lazy(() =>
  import("./pages/contact-us/ContactUs")
);
const Aboutus = React.lazy(() => import("@/pages/about-us/AboutUs"));

// create_post_buffalo
const Address = React.lazy(() => import("@/pages/profile/address/address"));
const UploadPhotos = React.lazy(() =>
  import("./_component/from/addphoto/addphoto")
);
const PreviewListingPage = React.lazy(() =>
  import("./_component/from/previewpage/previewpage")
);
const PreviewPostingPage = React.lazy(() =>
  import("./_component/from/previewPostingPage")
);


const MachineryService = React.lazy(() =>
  import("./_component/from/serviceprovider/machineryservice/machinery_service")
);
const BoreWell = React.lazy(() =>
  import("./_component/from/serviceprovider/borewell/create_post_boreWell")
);
const BankService = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/bankServices/create_post_bankServices"
  )
);
const InsurenceService = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/insurenceService/create_post_InsurenceService"
  )
);
const WaterPump = React.lazy(() =>
  import("./_component/from/serviceprovider/moterpumps/motor_water_pump_repair")
);
const HourseDogTranair = React.lazy(() =>
  import("./_component/from/serviceprovider/horsedogtrainer/hoursedogtrainer")
);
const ArtificialLiveStockBreeding = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/livestockbreeding/artificialLiveStockBreeding/artificial_live_stock_breeding"
  )
);
const NaturalLiveStockBreeding = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/livestockbreeding/naturalLiveStockBreeding/natural_live_stock_breeding"
  )
);

const ArtificialHorseBreeding = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/horsebreeding/artificialhourseBreeding/hoursebreeding_breeding"
  )
);
const NaturalHorseBreeding = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/horsebreeding/naturalhourseBreeding/natural_hourse_breeding"
  )
);
const WeldingFab = React.lazy(() =>
  import(
    "./_component/from/serviceprovider/wilddingfabricationworks/welding_fab"
  )
);

const WatersoilTestmanure = React.lazy(() =>
  import("./_component/from/serviceprovider/waterandsoiltest/menure/menure")
);

const AssigneeOTP = React.lazy(() =>
  import("./_component/from/assigneeotp/assigneeOTP")
);

const MyListing = React.lazy(() => import("./_component/MyListing"));
const Dealer = React.lazy(() => import("./_component/MyListing/Dealer/Dealer"));
const ServiceProviderList = React.lazy(() =>
  import("./_component/MyListing/serviceprovider/serviceprovider")
);
const MyPostings = React.lazy(() => import("./_component/MyPostings"));

const DealerPostings = React.lazy(() =>
  import("./_component/MyPostings/DealerPostings/DealerPostings")
);
const PreviewPosting = React.lazy(() =>
  import("./_component/MyPostings/previewposting/previewposting")
);

const ServiceProviderPosting = React.lazy(() =>
  import("./_component/MyPostings/serviceprovider/serviceprovider")
);
const AdzonePosting = React.lazy(() =>
  import("./_component/MyPostings/adzonePosting/adzonePosting")
);
const AdzonePreview = React.lazy(() =>
  import("./_component/MyPostings/adzonepreview/adzonepreview")
);

const OfferZonePosting = React.lazy(() =>
  import("./_component/MyPostings/offerZonePosting")
);
const PaymentSummary = React.lazy(() => import("./_component/PaymentSummary"));
const FileInvoice = React.lazy(() =>
  import("./_component/PaymentSummary/invoiceDetails/paymentInvoice")
);

const MyLeads = React.lazy(() => import("./pages/leads/MyLeads"));
const AdZone = React.lazy(() => import("./_component/adzone/AdZone"));
const MyAppoinments = React.lazy(() =>
  import("./pages/appointments/MyAppoinment")
);

/**Agent or Coordinator */
const AgentRankFeature = React.lazy(() => import("./pages/agent/AgentRankFeature"));
const AgentBankDetails = React.lazy(() => import("./pages/agent/AgentBankDetails"));
const AgentRefferals = React.lazy(() => import("./pages/agent/AgentRefferals"));
const AgentMisReport = React.lazy(() => import("./pages/agent/AgentMisReport"));

const SetMilk = React.lazy(() => import("./_component/Milk/SetMilk"));
const MilkCollection = React.lazy(() =>
  import("./_component/Milk/MilkCollection")
);
const FarmerMisReport = React.lazy(() =>
  import("./_component/Milk/FarmerMisReport")
);
const AdminsMisReport = React.lazy(() =>
  import("./_component/Milk/AdminsMisReport")
);
const FarmerPendingConfirmation = React.lazy(() =>
  import("./_component/Milk/FarmerPendingConfirmation")
);
const MilkCenterPendingConfirmation = React.lazy(() =>
  import("./_component/Milk/MilkCenterPendingConfirmation")
);
const EditMilkCollection = React.lazy(() =>
  import("./_component/Milk/EditMilkCollection")
);

const BuyListingDetails = React.lazy(() =>
  import("./_component/MyListing/BuyListingDetails/BuyListingDetails")
);
const MyTransaction = React.lazy(() =>
  import("./pages/transaction/MyTransaction")
);
const AddzoneList = React.lazy(() =>
  import("./_component/MyListing/AddZone/AddZone")
);
const OfferZoneListings = React.lazy(() =>
  import("./_component/MyListing/OfferZone/OfferZoneList")
);

const OfferZone = React.lazy(() => import("./_component/offer/OfferZone"));
const OfferResults = React.lazy(() =>
  import("./_component/offer/OfferResults")
);
const OfferInformation = React.lazy(() =>
  import("./_component/offer/OfferInformation")
);


const CreatePost = React.lazy(() => import("@/pages/post/CreatePost"));

Amplify.configure(awsconfig);

function App() {

  const [headerData, setHeaderData] = useState('');

  useEffect(() => {
    isLoggedInUser();
  }, []);
console.log('header data',headerData);
  return (
    <BrowserRouter>
      <LanguageProvider>
        <Provider store={store}>
          <Suspense fallback={<Loader />}>
            <Header setHeaderData={setHeaderData}  />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <Routes>
              <Route  path="/" element={<Home headerData={headerData} />} />
              <Route path="/home" element={<Home headerData={headerData} />} />
              {/* This below 1 route is for testing new way of create post page*/}
              <Route exact path="/posts/:postType/:categoryId" element={<CreatePost />} />
              {/* Above 1 rooute is for testing new way of create post page */}
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/resetpassword" element={<ResetPassword />} />
              <Route exact path={CONTACT_US} element={<ContactUs />} />
              <Route exact path={ABOUT_US} element={<Aboutus />} />

              <Route exact path="/sell/:tempName" element={<Categories />} />
              <Route path={"/services/:tempName"} element={<SearchCategories />} />
              <Route path={"/buy/:tempName"} element={<Buy />} />

              <Route exact path={ADDRESS} element={<Address />} />
              <Route exact path={UPLOADPHOTOS} element={<UploadPhotos />} />
              <Route exact path={PREVIEWLISTINGPAGE} element={<PreviewListingPage />} />
              <Route exact path={PREVIEWPOSTINGPAGE} element={<PreviewPostingPage />} />

              {/* --------------- create post pages --------------- */}
              <Route path="/post" element={<BaseCreatePost />}>
                <Route exact path={MACHINERYREAPER} element={<Reaper />} />
                <Route exact path={MACHINERYBALERS} element={<Baler />} />
                <Route exact path={TRACTOR} element={<Tractor />} />
                <Route exact path={HARVESTER} element={<Harvester />} />
                <Route exact path={FI} element={<FarmingImplementation />} />
                <Route exact path={TRACTORTRADER} element={<TractorTraders />} />
                <Route exact path={HARVESTERTRADERS} element={<HarvesterTraders />} />
                <Route exact path={FITRADERS} element={<FarmingImplementationTraders />} />
                <Route exact path={REAPERTRADER} element={<ReaperTrader />} />
                <Route exact path={BALERSTRADER} element={<BalerTraders />} />
                <Route exact path={TYRE} element={<Tyre />} />
                <Route exact path={CROPREG} element={<CropsReg />} />
                <Route exact path={BUFFALO} element={<Buffalo />} />
                <Route exact path={CAMEL} element={<Camel />} />
                <Route exact path={COW} element={<Cow />} />
                <Route exact path={DOG} element={<Dog />} />
                <Route exact path={GOAT} element={<Goat />} />
                <Route exact path={OX} element={<Ox />} />
                <Route exact path={SHEEP} element={<Sheep />} />
                <Route exact path={SILAGE} element={<Silage />} />
                <Route exact path={HORSETRADERS} element={<HorseTraders />} />
                <Route exact path={BIOMASSTRADERS} element={<BiomassTrader />} />
                <Route exact path={FRUITSVEGREG} element={<FruitInformationForm />} />
                <Route exact path={PROPERTYINFORMATIONFORM} element={<PropertyInformationForm />} />
                <Route exact path={PFS} element={<PFSInformation />} />
                <Route exact path={DOMESTIC} element={<Domestic />} />
                <Route exact path={AGRICULTURE} element={<Agriculture />} />
                <Route exact path={INDUSTRIAL} element={<Industrial />} />
                <Route exact path={MACHINERYSERVICE} element={<MachineryService />} />
                <Route exact path={BOREWELL} element={<BoreWell />} />
                <Route exact path={BANKSERVICE} element={<BankService />} />
                <Route exact path={INSURENCESERVICE} element={<InsurenceService />} />
                <Route exact path={WATERPUMP} element={<WaterPump />} />
                <Route exact path={HOURSEDOGTRANAIR} element={<HourseDogTranair />} />
                <Route exact path={ARTIFICIALLIVESTOCKBREEDING} element={<ArtificialLiveStockBreeding />} />
                <Route exact path={NATURALLIVESTOCKBREEDING} element={<NaturalLiveStockBreeding />} />
                <Route exact path={ARTIFICIALHORSEBREEDING} element={<ArtificialHorseBreeding />} />
                <Route exact path={NATURALHORSEBREEDING} element={<NaturalHorseBreeding />} />
                <Route exact path={WELDINGFAB} element={<WeldingFab />} />
                <Route exact path={WATERSOILTESTMANURE} element={<WatersoilTestmanure />} />
                <Route exact path={OFFERINFORMATION} element={<OfferInformation />} />
              </Route>

              {/* --------------- lisitings routes --------------- */}
              <Route path="/listings" element={<BaseListing />}>
                <Route path={GENERAL_LISTING} element={<MyListing />} />
                <Route exact path={DEALER_LISTING} element={<Dealer />} />
                <Route exact path={SERVICEPROVIDERLIST} element={<ServiceProviderList />} />
                <Route exact path={OFFERZONELISTINGS} element={<OfferZoneListings />} />
                <Route exact path={ADDZONELIST} element={<AddzoneList />} />
              </Route>

              {/* --------------- postings routes ---------------*/}
              <Route path="/postings" element={<BasePosting />}>
                <Route exact path={""} element={<MyPostings />} />
                <Route exact path={DEALERPOSTINGS} element={<DealerPostings />} />
                <Route exact path={SERVICEPROVIDERPOSTING} element={<ServiceProviderPosting />} />
                <Route exact path={OFFERZONEPOSTING} element={<OfferZonePosting />} />
                <Route exact path={ADZONE_POSTING} element={<AdzonePosting />} />
              </Route>

              <Route exact path={PAYMENTSUMMARY} element={<PaymentSummary />} />
              <Route exact path={DEALERBUYPRESEARCH} element={<DealerBuyPreSearch />} />
              <Route exact path={SEARCHRESULTPAGE} element={<SearchResultPage />} />

              <Route exact path={GREATDEALS} element={<GreatDealsSearch />} />
              <Route exact path={VERIFYREGISTER} element={<VerifyOTP />} />
              <Route exact path={REGISTERAS} element={<RegisterAs />} />

              <Route exact path={AGENTPROVIDER} element={<AgentsProvider />} />
              <Route exact path={DEALERPROVIDER} element={<DealerProvider />} />
              <Route exact path={FARMERPROVIDER} element={<FarmerProvider />} />
              <Route exact path={SERVICEPROVIDER} element={<ServiceProvider />} />
              <Route exact path={OTHERSPROVIDER} element={<OthersProvider />} />
              <Route exact path={CATEGORIESITEM} element={<CategoriesItem />} />

              {/* Terms and Condition , Policy routes */}
              <Route exact path={PRIVACYPOLICY} element={<Privacypolicy />} />
              <Route exact path={TERMCONDITION} element={<TermCondition />} />
              <Route exact path={REFUNDPOLiCY} element={<RefundPolicy />} />
              <Route exact path={ADSPOLICY} element={<AdsPolicy />} />

              {/* dealer, service provider, farmer, agent, other registration form routes */}
              <Route exact path={DEALERONBOARDFROM} element={<DealerRegisterForm />} />
              <Route exact path={SERVICESREG} element={<ServiceProviderReg />} />
              <Route exact path={FARMERREG} element={<Farmer />} />
              <Route exact path={AGENTREG} element={<Agent />} />
              <Route exact path={OTHERSREG} element={<Others />} />

              <Route exact path={DEALERPLANSLIST} element={<DealerPlansList />} />
              <Route exact path={"/invoicelist"} element={<FileInvoice />} />

              {/* this /PreviewPosting route might not be in use instead of this PreviewPostingPage route is in use */}
              <Route exact path={"/PreviewPosting"} element={<PreviewPosting />} />

              <Route exact path={"/adzonePreviewPosting"} element={<AdzonePreview />} />
              <Route exact path={"/adzonePreviewListing"} element={<AdzonePreview />} />
              <Route path={"/assigneeOTP"} element={<AssigneeOTP />} />

              <Route path="/MyLeads" element={<MyLeads />} />
              <Route path={ADZONE} element={<AdZone />} />
              <Route path="/adzone/:id" element={<AdZone />} />
              <Route path="/myappoinments" element={<MyAppoinments />} />

              <Route path="/agent/rank-feature" element={<AgentRankFeature />} />
              <Route path="/agent/mis-report" element={<AgentMisReport />} />
              <Route path={AGENT_BANK_DETAILS} element={<AgentBankDetails />} />
              <Route path={AGENT_CLUSTER} element={<AgentRefferals />} />

              {/* Milk module */}
              <Route path="/SetMilk" element={<SetMilk />} />
              <Route path="/MilkCollection" element={<MilkCollection />} />
              <Route path="/EditMilkCollection" element={<EditMilkCollection />} />
              <Route path="/FarmerMisReport" element={<FarmerMisReport />} />
              <Route path="/DairyCenterMisReport" element={<AdminsMisReport />} />
              <Route path="/FarmerPendingConfirmation" element={<FarmerPendingConfirmation />} />
              <Route path="/MilkCenterPendingConfirmation" element={<MilkCenterPendingConfirmation />} />
              <Route path="/BuyListings" element={<BuyListingDetails />} />
              <Route path={MY_TRANSACTION} element={<BaseTransaction />}>
                <Route path={""} element={<MyTransaction />} />
                <Route exact path={`${MY_TRANSACTION}/:id`} element={<TransactionDetails />} />
              </Route>
              <Route path={MY_PROFILE} element={<Profile />} />
              <Route path={PROFILE_DETAILS} element={<ProfileDetail />} />
              <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />
              <Route path={MY_DEALERSHIP} element={<Dealership />} />
              <Route path="/dealership-detail/:id" element={<DealershipDetail />} />
              <Route path={MY_ADDRESS} element={<MyAddress />} />
              <Route path={OFFERZONE} element={<OfferZone />} />
              <Route path={OFFERRESULTS} element={<OfferResults />} />
            </Routes>
            <Footer />
          </Suspense>
        </Provider>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
